// UI框架
import React from 'react';
import { connect } from 'react-redux';
import {AuthComponent} from 'publicHOC';
// UI组件
import {Tabs} from 'libs';
// 业务组件
import {LangConsumer} from 'utils/LangProvider';
import {bindLifecycle} from 'utils/bindLifecycle';
import * as mapDispatchToProps from '../../../store/views/AIConfig/TrafficEvent/controller';
import { clone } from '../../../common';
import DetectionDrawConfig from './DetectionDrawConfig';
import AreaEventConfig from './AreaEventConfig';
import LaneEventConfig from './LaneEventConfig';
import ViolationCodeConfig from '../IVSIntelliCityMgr/ViolationCodeConfig';
import FileParamConfig from '../IVSIntelliCityMgr/FileParamConfig';
import AdvancedSet from '../ivsTrafficIllegal/AdvancedSet';
import SDTrafficAdvanceConfiguration from '../IvsSDTraffic/SDTrafficAdvanceConfiguration';
import TrafficOsd from '../TrafficOsd';
import CutoutSetConfig from './CutoutSetConfig';
import WebApp from '../../../webapp';
import RPC from 'api';
// 样式
import './index.less';

const _name_ = 'TrafficEvent';
const { TabPane } = Tabs;
const sceneType = 'TrafficIllegal'; //该智能大类在协议上是用于违法抓拍业务，不仅仅是用在违停抓拍业务

// 页面需要的数据
const mapStateToProps = (state) => {
    const { TrafficEventConfig, publicState, webCaps } = state;

    return { TrafficEventConfig, publicState, webCaps };
};

class TrafficEventConfigComponent extends AuthComponent {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.state = {
            key: '1',
            laneTab: false, //是否存在车道相关
        };
        this.intelliTrackerInterval = null; //智能跟踪定时器对象
        this.enableFileParamFlag = true;
    }

    componentDidMount () {
        let tempRuleArr = [];
        for (let i = 0; i < WebApp.ChannelCapsInfo.length; i++) {
            const SupportedRules = WebApp.ChannelCapsInfo[i].SupportedScenes[sceneType].SupportedRules;
            if (SupportedRules) {
                Object.keys(SupportedRules).forEach((item) => {
                    //卡口和违停复用违停大类，因为需要区分区域规则和车道规则，加能力
                    if (SupportedRules[item].RuleDrawType === 'Lanes') {
                        tempRuleArr.push(item);
                    }
                });
            }
        }
        this.setState({laneTab: tempRuleArr.length > 0});

        this.timeIntelliTracker();
        this.enableFileParamFlag = this.enableFileParam();
    }

    componentDidActivate () {
        this.timeIntelliTracker();
        this.enableFileParamFlag = this.enableFileParam();
    }

    componentWillUnactivate () {
        this.clearTime();
    }

    componentWillUnmount () {
        this.clearTime();
    }

    timeIntelliTracker = () => {
        if (WebApp.DeviceType.indexOf('SD') > -1) {
            this.intelliTrackerInterval = setInterval(() => {
                if (this.intelliTrackerInterval === 0) {
                    return;
                }
                for (let i = 0; i < WebApp.CHANNEL_NUMBER; i++) {
                    RPC.devIntelliTrackerManager('stop', null, i);
                }
            }, 1000);
        }
    }

    clearTime = () => {
        if (WebApp.DeviceType.indexOf('SD') > -1) {
            clearInterval(this.intelliTrackerInterval);
            this.intelliTrackerInterval = 0;
            for (let i = 0; i < WebApp.CHANNEL_NUMBER; i++) {
                RPC.devIntelliTrackerManager('start', null, i);
            }
        }
    }

    enableFileParam () {
        let {pageChannels, webCaps} = this.props;
        const channel = pageChannels.length ? pageChannels[0] : 0;
        let supportRules = WebApp.ChannelCapsInfo[channel].SupportedScenes[sceneType].SupportedRules;
        if (!webCaps.supportUserImage || !webCaps.supportUserImage[sceneType]) {
            return true;
        }
        return Object.keys(supportRules).some((item) => {
            return webCaps.supportUserImage[sceneType].includes(item);
        });
    }

    handleTabChange = (key) => {
        this.setState({
            key
        });
    }

    render () {
        let {$t, preStep, pageChannels, realPlanType, currentPlanID} = this.props;
        let {key, laneTab} = this.state;
        return (
            <div className={`_${_name_}_`}>
                <Tabs activeKey={key} onTabClick={this.handleTabChange}>
                    <TabPane tab={$t('ivs.SetScene')} key="1">
                        {key === '1' ? <DetectionDrawConfig tabKey={key} keepAlive={false} realPlanType={realPlanType} currentPlanID={currentPlanID} laneTab={laneTab} /> : null}
                    </TabPane>
                    <TabPane tab={$t('ivs.RegionalEvent')} key="2">
                        {key === '2' ? <AreaEventConfig handleTabChange={this.handleTabChange} tabKey={key} keepAlive={false} realPlanType={realPlanType} currentPlanID={currentPlanID}/> : null}
                    </TabPane>
                    {laneTab && <TabPane tab={$t('ivs.LaneEvent')} key="3">
                        {key === '3' ? <LaneEventConfig handleTabChange={this.handleTabChange} tabKey={key} keepAlive={false} realPlanType={realPlanType} currentPlanID={currentPlanID} type={sceneType} /> : null}
                    </TabPane>}
                    <TabPane tab={$t('ivs.BreakRulesCode')} key="4">
                        {key === '4' && <ViolationCodeConfig
                            tabKey={key}
                            {...{ preStep, pageChannels, ClassType: sceneType}}
                        />}
                    </TabPane>
                    <TabPane tab={$t('ivs.OriginalDrawingOSD')} key="5">
                        {key === '5' && <TrafficOsd
                            ref={this.TrafficOsd}
                            tabKey={key}
                            {...{ preStep, pageChannels, curStepOSD: 'TrafficEvent', ClassType: sceneType, osdType: 'OSD', addParams: ['TrafficImage'], supportEditOSDAll: true, currentPlanID}}
                        />}
                    </TabPane>
                    <TabPane tab={$t('ivs.CompoundDrawingOSD')} key="6">
                        {key === '6' && <TrafficOsd
                            ref={this.TrafficMergeOSD}
                            tabKey={key}
                            {...{ preStep, pageChannels, curStepOSD: 'TrafficEvent', ClassType: sceneType, osdType: 'MergeOSD', addParams: ['TrafficImage','TrafficSnapMosaic2'], supportEditOSDAll: true, currentPlanID}}
                        />}
                    </TabPane>
                    {WebApp.DeviceType.includes('SD') && <TabPane tab={$t('ivs.FileParam')} key="7" disabled={!this.enableFileParamFlag}>
                        {key === '7' && <FileParamConfig
                            tabKey={key}
                            {...{ preStep, pageChannels, ClassType: sceneType}}
                        />}
                    </TabPane>}
                    {laneTab && <TabPane tab={$t('ivs.SnapCutoutConf')} key="9">
                        {key === '9' && <CutoutSetConfig
                            tabKey={key}
                            {...{ preStep, pageChannels, ClassType: sceneType, currentPlanID}}
                        />}
                    </TabPane>}
                    {laneTab && <TabPane tab={$t('com.AdvancedSet')} key="8">
                        {key === '8' ? <SDTrafficAdvanceConfiguration tabKey={key} keepAlive={false} realPlanType={realPlanType} type={sceneType} clearTime={this.clearTime} timeIntelliTracker={this.timeIntelliTracker} currentPlanID={currentPlanID}/> : null}
                    </TabPane>}
                </Tabs>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef:true})(LangConsumer(bindLifecycle(TrafficEventConfigComponent)));